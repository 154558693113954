import { HTTP } from '@/libs/https.js'
import config from '@/libs/config.js'

class MessageSendModel extends HTTP {
  getMessageList (data) {
    return new Promise((resolve, reject) => {
      this.axiosPost({
        url: config.API.GET_MESSAGE_SEND_LIST,
        data: data,
        header: 'application/json',
        success (data) {
          resolve({
            data,
            status: 0
          });
        },
        error (error) {
          resolve({
            error,
            status: - 1
          })
        }
      });
    });
  }
  messageAdd (data) {
    return new Promise((resolve, reject) => {
      this.axiosPost({
        url: config.API.MESSAGE_SEND_ADD,
        data: data,
        header: 'application/json',
        success (data) {
          resolve({
            data,
            status: 0
          });
        },
        error (error) {
          resolve({
            error,
            status: - 1
          })
        }
      });
    });
  }
  messageDelete (data) {
    return new Promise((resolve, reject) => {
      this.axiosPost({
        url: config.API.MESSAGE_SEND_DELETE,
        data: data,
        header: 'application/json',
        success (data) {
          resolve({
            data,
            status: 0
          });
        },
        error (error) {
          resolve({
            error,
            status: - 1
          })
        }
      });
    });
  }
  messageModify (data) {
    return new Promise((resolve, reject) => {
      this.axiosPost({
        url: config.API.MESSAGE_SEND_MODIFY,
        data: data,
        header: 'application/json',
        success (data) {
          resolve({
            data,
            status: 0
          });
        },
        error (error) {
          resolve({
            error,
            status: - 1
          })
        }
      });
    });
  }
  getMessageListCondition (data) {
    return new Promise((resolve, reject) => {
      this.axiosPost({
        url: config.API.MESSAGE_SEND_LIST_CONDITION,
        data: data,
        header: 'application/json',
        success (data) {
          resolve({
            data,
            status: 0
          });
        },
        error (error) {
          resolve({
            error,
            status: - 1
          })
        }
      });
    });
  }
  getMessageDetail (data) {
    return new Promise((resolve, reject) => {
      this.axiosPost({
        url: config.API.GET_MESSAGE_DETAIL,
        data: data,
        header: 'application/json',
        success (data) {
          resolve({
            data,
            status: 0
          });
        },
        error (error) {
          resolve({
            error,
            status: - 1
          })
        }
      });
    });
  }
  deleteMessage (data) {
    return new Promise((resolve, reject) => {
      this.axiosPostArray({
        url: config.API.DELETE_MESSAGE,
        data: data,
        header: 'application/json',
        success (data) {
          resolve({
            data,
            status: 0
          });
        },
        error (error) {
          resolve({
            error,
            status: - 1
          })
        }
      });
    });
  }

  getCampusAnnouncementDetails(data) {
    return new Promise((resolve, reject) => {
      this.axiosPost({
        url: config.API.GET_CAMPUS_ANNOUNCEMENT_DETAIL,
        data: data,
        header: 'application/json',
        success (data) {
          resolve({
            data,
            status: 0
          });
        },
        error (error) {
          resolve({
            error,
            status: - 1
          })
        }
      });
    })
  }

  listOfReviewsOnCampusSide (data) {
    return new Promise((resolve, reject) => {
      this.axiosPost({
        url: config.API.LIST_OF_REVIEWS_ON_THE_CAMPUS_SIDE,
        data: data,
        header: 'application/json',
        success (data) {
          resolve({
            data,
            status: 0
          });
        },
        error (error) {
          resolve({
            error,
            status: - 1
          })
        }
      });
    })
  }

  campusCommentsTop (data) {
    return new Promise((resolve, reject) => {
      this.axiosPost({
        url: config.API.CAMPUS_COMMENTS_TOP,
        data: data,
        header: 'application/json',
        success (data) {
          resolve({
            data,
            status: 0
          });
        },
        error (error) {
          resolve({
            error,
            status: - 1
          })
        }
      });
    })
  }

  campusCommentsDelete (data) {
    return new Promise((resolve, reject) => {
      this.axiosPost({
        url: config.API.CAMPUS_COMMENTS_DELETE,
        data: data,
        header: 'application/json',
        success (data) {
          resolve({
            data,
            status: 0
          });
        },
        error (error) {
          resolve({
            error,
            status: - 1
          })
        }
      });
    })
  }

  getPersonList (data) {
    return new Promise((resolve, reject) => {
      this.axiosPost({
        url: config.API.GET_PERSON_LIST,
        data: data,
        header: 'application/json',
        success (data) {
          resolve({
            data,
            status: 0
          });
        },
        error (error) {
          resolve({
            error,
            status: - 1
          })
        }
      });
    })
  }
}

export { MessageSendModel }
